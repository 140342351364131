<template>
  <div>
    <vs-row justify="right" :style="{ paddingTop: '2%' }">
      <v-btn v-if="products.length > 0" outlined :color="COLORS.SECONDARY" @click.stop="drawer = !drawer">
        <span :style="{ color: COLORS.SECONDARY }" class="material-symbols-outlined">
          tune
        </span>
        <h5 :style="{ color: COLORS.SECONDARY, paddingTop: '8px' }">Filter & Sort </h5>
      </v-btn>
    </vs-row>
    <vs-row v-if="products.length > 0">
      <vs-col v-if="drawer" :w="12">
        <v-list>
          <v-list-group v-model="filterPrice" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title style="font-size: 18px; ">Price</v-list-item-title>
              </v-list-item-content>
            </template>
            <div style="text-align: left;">
              <v-btn outlined rounded :filterPrice="filterPrice == 'all'" @click="
                filterPrice = 'all';
              forceUpdate();
              ">
                All
              </v-btn>
              <v-btn outlined rounded :filterPrice="filterPrice == 1000" @click="
                filterPrice = 1000;
              forceUpdate();
              ">
                under 1000
              </v-btn>
              <v-btn outlined rounded :filterPrice="filterPrice == 1500" @click="
                filterPrice = 1500;
              forceUpdate();
              ">
                under 1500
              </v-btn>
              <v-btn outlined rounded :filterPrice="filterPrice == 2000" @click="
                filterPrice = 2000;
              forceUpdate();
              ">
                under 2000
              </v-btn>
              <v-btn outlined rounded :filterPrice="filterPrice == 2500" @click="
                filterPrice = 2500;
              forceUpdate();
              ">
                under 2500
              </v-btn>
              <v-btn outlined rounded :filterPrice="filterPrice == 3000" @click="
                filterPrice = 3000;
              forceUpdate();
              ">
                under 3000
              </v-btn>
            </div>
          </v-list-group>
          <v-list-group v-model="filterSort" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title style="font-size: 18px;">Sort</v-list-item-title>
              </v-list-item-content>
            </template>
            <div style="text-align: left;">
              <v-btn @click.prevent="filterLH()" outlined rounded :filterSort="filterSort == 'plh'">
                Price: Low-High
              </v-btn>
              <v-btn @click.prevent="filterHL()" outlined rounded :filterSort="filterSort == 'phl'">
                Price: High-Low
              </v-btn>
              <v-btn @click.prevent="filterDL()" outlined rounded :filterSort="filterSort == 'la'">
                Latest
              </v-btn>
              <v-btn @click.prevent="filterDO()" outlined rounded :filterSort="filterSort == 'ol'">
                Oldest
              </v-btn>
            </div>
          </v-list-group>
        </v-list>
      </vs-col>
      <vs-col :style="{ padding: '2% 0' }" :w="12">
        <vs-row justify="left">
          <vs-col :w="$vuetify.breakpoint.smAndDown ? 6 : 3" :style="{ fontSize: '14px', padding: '0.5%' }"
            v-for="item in showProducts" v-bind:key="item.productId">
            <router-link :style="{ textDecoration: 'none' }"
              :to="{ name: 'ViewProduct', params: { productId: item.productId } }">
              <v-card tile flat :style="{ paddingBottom: '10%' }">
                <v-img :height="$vuetify.breakpoint.smAndDown ? '250px' : '400px'" v-bind:src="item.productImages[0]">
                  <template v-slot:placeholder>
                    <v-skeleton-loader class="mx-auto" width="100%" height="100%" type="image"></v-skeleton-loader>
                  </template>
                </v-img>
                <div :style="{ padding: '2%' }">
                  <h5> {{ item.productTitle }} </h5>
                  <div> <i class="fa-solid fa-bangladeshi-taka-sign"></i><strong> {{ item.productPrice }} </strong>
                  </div>
                  <div :style="{ color: COLORS.RED }" v-if="item.productQuantity == 0"> <strong>Out of Stock</strong>
                  </div>
                  <div :style="{ color: COLORS.RED }" v-if="item.productQuantity > 0 && item.productQuantity < 5">
                    <strong>Only {{ item.productQuantity }} available in stock!</strong>
                  </div>
                  <div v-if="item.productQuantity > 0 && item.productColors.length " :style="{ width: '85%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }">
                    Available in: {{ item.productColors.join(', ') }}
                  </div>
                  <div :style="{ fontSize: '14px' }"> <strong>{{ filteredTags(item).join(', ') }}</strong></div>
                </div>
              </v-card>
            </router-link>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
    <vs-row v-if="products.length === 0" :style="{ padding: '5%', color: COLORS.BLACK }" justify="center"
      align="center">
      <div :style="{ fontSize: '24px', padding: '2%', textAlign: 'center' }">
        No products found
      </div>
    </vs-row>
  </div>
</template>

<script>
import { COLORS } from '@/plugins/branding.js';

export default {
  name: 'Products',
  props: ['products'],
  data: () => ({
    drawer: false,
    filterPrice: 'all',
    showProducts: [],
    filterSort: null,
    COLORS,
  }),
  async mounted() {
    await this.forceUpdate();
  },
  methods: {
    filteredTags(item) {
      return item.productTags.filter(tag => tag === 'Best Seller' || tag === 'New Collection');
    },
    forceUpdate() {
      this.showProducts = [];
      if (this.filterPrice !== 'all') {
        this.products.forEach((element) => {
          if (Number(element.productPrice) < this.filterPrice) {
            this.showProducts.push(element);
          }
        });
      } else {
        this.showProducts = this.products;
      }
    },
    filterLH() {
      this.showProducts.sort((a, b) =>
        Number(a.productPrice) > Number(b.productPrice) ? 1 : -1
      );
    },
    filterHL() {
      this.showProducts.sort((a, b) =>
        Number(a.productPrice) > Number(b.productPrice) ? -1 : 1
      );
    },
    filterDL() {
      this.showProducts.sort(
        (a, b) => new Date(b.productDate) - new Date(a.productDate)
      );
    },
    filterDO() {
      this.showProducts.sort(
        (a, b) => new Date(a.productDate) - new Date(b.productDate)
      );
    },
  },
};
</script>